import React from 'react'
import Navbar from '../components/Navbar'
import OlivesComponent from '../components/Olives'
import Footer from '../components/Footer'
import Scroll from '../components/Utils'

const Olives = () => {
    return (
        <>
            <Scroll />
            <Navbar />
            <OlivesComponent />
            <Footer />
        </>
    )
}

export default Olives