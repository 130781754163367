import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Homepage from './pages/Homepage';
import Crops from './pages/Crops';
import Esg from './pages/Esg';
import Contact from './pages/Contact';
import EsgDetails from './pages/EsgDetails';
import Olives from './pages/Olives';
import Citrus from './pages/Citrus';
import Almonds from './pages/Almonds';
import Pistachios from './pages/Pistachios';

const App = () => {

  useEffect(() => {
    document.title = 'Trifolium Farms';
  }, []);

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/crops' element={<Crops />} />
        <Route path='/esg' element={<Esg />} />
        <Route path='/contact' element={<Contact />} />

        <Route path='/esg/details' element={<EsgDetails />} />
        <Route path='/crops/olives' element={<Olives />} />
        <Route path='/crops/citrus' element={<Citrus />} />
        <Route path='/crops/almonds' element={<Almonds />} />
        <Route path='/crops/pistachios' element={<Pistachios />} />
      </Routes>
    </Router>
  );
}

export default App;
