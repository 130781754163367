import React from 'react'
import Navbar from '../components/Navbar'
import CitrusComponent from '../components/Citrus'
import Footer from '../components/Footer'
import Scroll from '../components/Utils'

const Citrus = () => {
    return (
        <>
            <Scroll />
            <Navbar />
            <CitrusComponent />
            <Footer />
        </>
    )
}

export default Citrus