import React from 'react'
import Navbar from '../components/Navbar'
import CropsComponent from '../components/Crops'
import Footer from '../components/Footer'
import Scroll from '../components/Utils'

const Crops = () => {
    return (
        <>
            <Scroll />
            <Navbar />
            <CropsComponent />
            <Footer />
        </>
    )
}

export default Crops