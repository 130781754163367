import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import Almonds from '../../assets/Common/Almonds.png';
import Citrus from '../../assets/Common/Citrus.png';
import Olives from '../../assets/Common/Olives.png';
import Pistachios from '../../assets/Common/Pistachios.png';
import Picture1 from '../../assets/SlideshowCROPS/1.png';
import Picture2 from '../../assets/SlideshowCROPS/2.png';
import Picture4 from '../../assets/SlideshowCROPS/4.png';
import Picture5 from '../../assets/SlideshowCROPS/5.png';
import Picture6 from '../../assets/SlideshowCROPS/6.png';
import Picture7 from '../../assets/SlideshowCROPS/7.png';
import Picture8 from '../../assets/SlideshowCROPS/8.png';
import Picture9 from '../../assets/SlideshowCROPS/9.png';
import Picture10 from '../../assets/SlideshowCROPS/10.png';
 
const CropsComponent = () => {
    return (
        <div className='flex flex-col overflow-x-hidden'>
            <div className='md:h-[800px] h-[400px] overflow-x-hidden flex justify-center relative'>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    centeredSlides={true}
                    modules={[Autoplay]}
                    className="h-full flex justify-center items-center"
                >
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture1} alt='Crops Slideshow' className='h-full object-cover object-top w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture2} alt='Crops Slideshow' className='h-full object-cover object-top w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture4} alt='Crops Slideshow' className='h-full object-cover object-top w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture5} alt='Crops Slideshow' className='h-full object-cover object-top w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture6} alt='Crops Slideshow' className='h-full object-cover object-top w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture7} alt='Crops Slideshow' className='h-full object-cover object-top w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture8} alt='Crops Slideshow' className='h-full object-cover object-top w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture9} alt='Crops Slideshow' className='h-full object-cover object-top w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture10} alt='Crops Slideshow' className='h-full object-cover object-top w-full' />
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className='w-full grid lg:grid-cols-2 grid-cols-1 md:gap-x-5 lg:p-14 p-5 gap-y-14'>
                <div className='lg:col-span-2 col-span-1 grid lg:grid-cols-2 grid-cols-1'>
                    <div className='col-span-1 xl:leading-[50px] xl:text-4xl lg:text-3xl text-2xl'>
                        The Iberian Peninsula is an ideal location for the development of permanent trees due to its diverse climatic conditions and secure water supply.
                        Our main focus is on the development of <b>olive</b>, <b>almond</b>, <b>pistachio</b> and <b>citrus</b> groves using the
                        latest technological advances, leading to profit maximization through increased efficiency and optimal use of the local natural resources.
                    </div>
                </div>
                <Link to='/crops/olives' className='col-span-1 flex flex-col items-start'>
                    <div className='flex items-center justify-center w-full md:h-[600px] h-[300px]'>
                        <img src={Olives} alt='Olives' className='w-full h-full object-cover object-center' />
                    </div>
                    <h1 className='md:text-4xl text-3xl mt-2'>Olives</h1>
                    <div className='text-lgreen md:text-4xl text-3xl'>Learn more</div>
                </Link>
                <Link to='/crops/citrus' className='col-span-1 flex flex-col items-start'>
                    <div className='flex items-center justify-center w-full md:h-[600px] h-[300px]'>
                        <img src={Citrus} alt='Citrus' className='w-full h-full object-cover object-center' />
                    </div>
                    <h1 className='md:text-4xl text-3xl mt-2'>Citrus</h1>
                    <div className='text-lgreen md:text-4xl text-3xl'>Learn more</div>
                </Link>
                <Link to='/crops/almonds' className='col-span-1 flex flex-col items-start'>
                    <div className='flex items-center justify-center w-full md:h-[600px] h-[300px]'>
                        <img src={Almonds} alt='Almonds' className='w-full h-full object-cover object-center' />
                    </div>
                    <h1 className='md:text-4xl text-3xl mt-2'>Almonds</h1>
                    <div className='text-lgreen md:text-4xl text-3xl'>Learn more</div>
                </Link>
                <Link to='/crops/pistachios' className='col-span-1 flex flex-col items-start'>
                    <div className='flex items-center justify-center w-full md:h-[600px] h-[300px]'>
                        <img src={Pistachios} alt='Pistachios' className='w-full h-full object-cover object-center' />
                    </div>
                    <h1 className='md:text-4xl text-3xl mt-2'>Pistachios</h1>
                    <div className='text-lgreen md:text-4xl text-3xl'>Learn more</div>
                </Link>
            </div>

        </div>
    )
}

export default CropsComponent