import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import Picture1 from '../../assets/SlideshowESG/1.png';
import Picture2 from '../../assets/SlideshowESG/2.png';
import Picture3 from '../../assets/SlideshowESG/3.png';
import Picture4 from '../../assets/SlideshowESG/4.png';
import Picture5 from '../../assets/SlideshowESG/5.png';
import Picture6 from '../../assets/SlideshowESG/6.png';
import Picture7 from '../../assets/SlideshowESG/7.png';
import Picture8 from '../../assets/SlideshowESG/8.png';

const EsgDetailsComponent = () => {
    return (
        <div className='flex flex-col  overflow-x-hidden'>
            <div className='md:h-[800px] h-[400px] overflow-x-hidden flex justify-center relative'>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    centeredSlides={true}
                    modules={[Autoplay]}
                    className="h-full flex justify-center items-center"
                >
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture1} alt='ESG' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture2} alt='ESG' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture3} alt='ESG' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture4} alt='ESG' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture5} alt='ESG' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture6} alt='ESG' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture7} alt='ESG' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture8} alt='ESG' className='h-full object-cover w-full' />
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className='w-full grid xl:grid-cols-2 lg:grid-cols-3 md:grid-cols-1 bg-black text-white py-5'>
                <div className='xl:col-span-1 lg:col-span-2 col-span-1 md:p-10 p-5 md:text-3xl text-xl md:leading-[40px] flex flex-col'>
                    Trifolium Farms management model focuses on the search for more efficient and sustainable practices to achieve long-term success. Our aim is to develop and improve productivity and consequently increase profitability, through a sustainable method of agriculture. Such method comprehends the process of making changes in a balanced way, in which the exploitation of resources, the direction of investments, the orientation of technological development and institutional change are in harmony. Our goal is to improve the current and future potential of agriculture to satisfy the human needs and aspirations.
                    <p className='mt-12'>Essentially based on the cultivation of woody trees, it provides from an ESG point of view:</p>
                </div>
            </div>
            <div className='w-full grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 bg-black text-white md:text-3xl text-xl md:leading-[40px] pb-20'>
                <div className='flex flex-col md:p-10 p-5 col-span-1'>
                    <div className='w-[80px] h-[10px] bg-lgreen'></div>
                    <h1 className='pl-5 pt-5'>
                        The promotion of sustainability and
                        the fight against climate change
                        through practices that reduce CO2 emissions and crops that favor Soil Carbon Sequestration.
                    </h1>
                </div>
                <div className='flex flex-col md:p-10 p-5 col-span-1'>
                    <div className='w-[80px] h-[10px] bg-lgreen'></div>
                    <h1 className='pl-5 pt-5'>
                        Agricultural practices based on
                        advanced techniques and technology that also help not only to maintain
                        current ecosystems, but also to improve them and that allow a sustainable and rational use of natural resources, essentially soil and water.
                    </h1>
                </div>
                <div className='flex flex-col md:p-10 p-5 col-span-1'>
                    <div className='w-[80px] h-[10px] bg-lgreen'></div>
                    <h1 className='pl-5 pt-5'>
                        Application of agronomic practices to preserve and improve biodiversity in its three categories: genetic, species and ecosystems.
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default EsgDetailsComponent