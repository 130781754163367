import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import Video from '../../assets/Homepage/Video.mp4';
import Logo from '../../assets/Homepage/LogoLight.png';
import Picture1 from '../../assets/home-fotosscrollLATERAL/1.png';
import Picture2 from '../../assets/home-fotosscrollLATERAL/2.png';
import Picture4 from '../../assets/home-fotosscrollLATERAL/4.png';
import Picture5 from '../../assets/home-fotosscrollLATERAL/5.png';
import Picture6 from '../../assets/home-fotosscrollLATERAL/6.png';
import Picture7 from '../../assets/home-fotosscrollLATERAL/7.png';
import Picture8 from '../../assets/home-fotosscrollLATERAL/8.png';
import Picture9 from '../../assets/home-fotosscrollLATERAL/9.png';

const HomepageComponent = () => {
    return (
        <div className='flex flex-col overflow-x-hidden'>
            <div className='w-full md:h-[900px] h-screen relative flex justify-center item-center'>
                <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className='w-full h-full object-cover'
                    preload="auto"
                >
                    <source src={Video} type="video/mp4" />
                </video>
                <div className='flex items-center justify-center absolute w-full h-full'>
                    <img src={Logo} alt='Logo Trifolium' className='md:w-auto w-[80%]' />
                </div>
            </div>
            <div className='w-full grid xl:grid-cols-2 lg:grid-cols-3 grid-cols-1'>
                <div className='col-span-1 flex lg:justify-end justify-start md:p-8 p-4 text-4xl font-bold'>
                    Welcome
                </div>
                <div className='xl:col-span-1 lg:col-span-2 col-span-1 flex justify-end md:p-8 p-4 lg:text-4xl md:text-3xl text-2xl lg:leading-[50px]'>
                    We are a leading institutional, fully integrated, agricultural business with over 25 years experience. Our objective is to maximise returns for our investors by offering a complete range of services ranging from property searching, land transformation, implantation, productivity and commercialisation while remaining committed to sustainability, biodiversity and the fight against climate change.
                </div>
            </div>
            <div className='md:h-[800px] h-[400px] overflow-x-hidden flex justify-center relative w-[200vw]'>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={3}
                    initialSlide={1}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    centeredSlides={true}
                    modules={[Autoplay, Pagination]}
                    className="h-full absolute flex justify-center items-center text-center overflow-x-hidden object-center -left-[25%]"
                >
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture1} alt='Trifolium Slide' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture2} alt='Trifolium Slide' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture4} alt='Trifolium Slide' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture5} alt='Trifolium Slide' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture6} alt='Trifolium Slide' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture7} alt='Trifolium Slide' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture8} alt='Trifolium Slide' className='h-full object-cover w-full' />
                    </SwiperSlide>
                    <SwiperSlide className='h-full relative'>
                        <img src={Picture9} alt='Trifolium Slide' className='h-full object-cover w-full' />
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default HomepageComponent