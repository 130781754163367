import React from 'react';
import { Link } from 'react-router-dom';

import Video from '../../assets/imagensALMONDS/Video.mp4';
import Olives from '../../assets/Common/Olives.png';
import Citrus from '../../assets/Common/Citrus.png';
import Pistachios from '../../assets/Common/Pistachios.png';
import Picture1 from '../../assets/imagensALMONDS/1.png';
import Picture2 from '../../assets/imagensALMONDS/2.png';
import Picture3 from '../../assets/imagensALMONDS/3.png';
import Picture4 from '../../assets/imagensALMONDS/4.png';
import Picture5 from '../../assets/imagensALMONDS/5.png';
import Picture6 from '../../assets/imagensALMONDS/6.png';
import Picture7 from '../../assets/imagensALMONDS/7.png';

const AlmondsComponent = () => {
    return (
        <div className='flex flex-col overflow-x-hidden'>
            <div className='w-full md:h-[800px] h-[400px] relative flex justify-center item-center'>
                <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className='w-full h-full object-cover'
                    preload="auto"
                >
                    <source src={Video} type="video/mp4" />
                </video>
            </div>
            <div className='w-full grid xl:grid-cols-2 lg:grid-cols-3 grid-cols-1'>
                <div className='col-span-1 flex lg:justify-end justify-start md:p-8 p-4 text-4xl font-bold'>
                    Almonds
                </div>
                <div className='xl:col-span-1 lg:col-span-2 col-span-1 flex justify-end md:p-8 p-4 lg:text-4xl md:text-3xl text-2xl lg:leading-[50px] whitespace-normal'>
                    We transform and manage extensive high density almond groves across the Iberian peninsula using the latest irrigation technology and a fully mechanised harvesting process.<br/>
                    Our almonds are various self-pollinating hard shell varieties that best adapt to local microclimates.<br/>
                    Most of our farms are powered by solar energy and we dedicate a conservation zone for migratory birds and native mammals in all projects.
                </div>
            </div>
            <div className='w-full grid grid-cols-2 md:gap-5 gap-2 pt-14 lg:px-14 md:px-5 px-2'>
                <div className='lg:col-span-1 col-span-2 w-full md:h-[600px] h-[300px] relative'>
                    <img src={Picture1} alt='Almond' className='w-full h-full absolute object-cover object-center' />
                </div>
                <div className='lg:col-span-1 col-span-2 grid grid-cols-2 md:gap-5 gap-2 relative'>
                    <div className='col-span-1 w-full relative lg:h-auto h-[300px]'>
                        <img src={Picture2} alt='Almond' className='w-full h-full absolute object-cover object-center' />
                    </div>
                    <div className='col-span-1 w-full relative lg:h-auto h-[300px]'>
                        <img src={Picture3} alt='Almond' className='w-full h-full absolute object-cover object-center' />
                    </div>
                    <div className='col-span-1 w-full relative lg:h-auto h-[300px]'>
                        <img src={Picture4} alt='Almond' className='w-full h-full absolute object-cover object-center' />
                    </div>
                    <div className='col-span-1 w-full relative lg:h-auto h-[300px]'>
                        <img src={Picture5} alt='Almond' className='w-full h-full absolute object-cover object-center' />
                    </div>
                </div>
                <div className='lg:col-span-1 col-span-2 w-full md:h-[600px] h-[300px] relative'>
                    <img src={Picture6} alt='Almond' className='w-full h-full absolute object-cover object-center' />
                </div>
                <div className='lg:col-span-1 col-span-2 w-full md:h-[600px] h-[300px] relative'>
                    <img src={Picture7} alt='Almond' className='w-full h-full absolute object-cover object-center' />
                </div>
            </div>
            <div className='border-t-2 border-black/20 lg:mx-14 md:mx-5 mx-2 mt-14'></div>
            <div className='lg:px-14 md:px-5 px-2 grid lg:grid-cols-3 grid-cols-2 py-14 w-full md:gap-5 gap-2'>
                <Link to={"/crops/citrus"} className='col-span-1 w-full relative'>
                    <div className='w-full relative md:h-[600px] h-[300px]'>
                        <img src={Citrus} alt='Citrus' className='w-full h-full absolute object-cover object-center' />
                    </div>
                    <h1 className='md:text-4xl text-2xl md:mt-5 mt-2'>Citrus</h1>
                    <div className='md:text-4xl text-2xl text-lgreen'>Learn more</div>
                </Link>
                <Link to={"/crops/olives"} className='col-span-1 w-full relative flex flex-col items-start'>
                    <div className='w-full relative md:h-[600px] h-[300px]'>
                        <img src={Olives} alt='Olives' className='w-full h-full absolute object-cover object-center' />
                    </div>
                    <h1 className='md:text-4xl text-2xl md:mt-5 mt-2'>Olives</h1>
                    <div className='md:text-4xl text-2xl text-lgreen'>Learn more</div>
                </Link>
                <Link to={"/crops/pistachios"} className='col-span-1 w-full relative flex flex-col items-start'>
                    <div className='w-full relative md:h-[600px] h-[300px]'>
                        <img src={Pistachios} alt='Pistachios' className='w-full h-full absolute object-cover object-center' />
                    </div>
                    <h1 className='md:text-4xl text-2xl md:mt-5 mt-2'>Pistachios</h1>
                    <div className='md:text-4xl text-2xl text-lgreen'>Learn more</div>
                </Link>
            </div>
        </div>
    )
}

export default AlmondsComponent