import React from 'react'
import Navbar from '../components/Navbar'
import PistachiosComponent from '../components/Pistachios'
import Footer from '../components/Footer'
import Scroll from '../components/Utils'

const Pistachios = () => {
    return (
        <>
            <Scroll />
            <Navbar />
            <PistachiosComponent />
            <Footer />
        </>
    )
}

export default Pistachios