import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx'
import Logo from '../../assets/Common/Logo.png';

const Navbar = () => {

    const location = useLocation();
    const [navbar, setNavbar] = useState(false);

    return (
        <>
            <div className={`fixed top-0 left-0 h-[100px] ${navbar ? "bg-white" : "bg-white/50"} flex items-center justify-between w-full lg:px-20 md:px-10 px-5 py-2 z-50`}>
                <div className='h-full flex items-center'>
                    <Link to={"/"}><img src={Logo} alt='Trifolium Logo' /></Link>
                </div>
                <div className='md:flex hidden items-center lg:gap-x-8 gap-x-3 text-black text-2xl'>
                    <Link to="/" className={location.pathname === "/" && 'text-lgreen'}>Company</Link>
                    <Link to="/crops" className={location.pathname === "/crops" && 'text-lgreen'}>Crops</Link>
                    <Link to="/esg" className={location.pathname === "/esg" && 'text-lgreen'}>ESG</Link>
                    <Link to="/contact" className={location.pathname === "/contact" && 'text-lgreen'}>Contact</Link>
                </div>
                <button onClick={() => { navbar ? setNavbar(false) : setNavbar(true) }} className='md:hidden block text-4xl'>
                    <RxHamburgerMenu />
                </button>
            </div>
            {navbar &&
                <div className='fixed md:hidden top-[100px] left-0 h-auto flex flex-col bg-white w-full z-50 items-center justify-center text-3xl gap-2 py-5'>
                    <Link to="/" className={location.pathname === "/" && 'text-lgreen'}>Company</Link>
                    <Link to="/crops" className={location.pathname === "/crops" && 'text-lgreen'}>Crops</Link>
                    <Link to="/esg" className={location.pathname === "/esg" && 'text-lgreen'}>ESG</Link>
                    <Link to="/contact" className={location.pathname === "/contact" && 'text-lgreen'}>Contact</Link>
                </div>
            }
        </>
    )
}

export default Navbar